
















import {Component, Vue} from 'vue-property-decorator'
import DrawButtonLine from '@/components/drawings/buttons/DrawButtonLine.vue'
import DrawButtonOval from '@/components/drawings/buttons/DrawButtonOval.vue'
import DrawingCanvasComponent from '@/components/drawings/DrawingCanvas.vue'
import DrawButtonPolyline from '@/components/drawings/buttons/DrawButtonPolyline.vue'
import DeleteButtonComponent from '@/components/drawings/buttons/DeleteButton.vue'

@Component({
  components: {
    'line-button': DrawButtonLine,
    'oval-button': DrawButtonOval,
    'freedraw-button': DrawButtonPolyline,
    'delete-button': DeleteButtonComponent,
    'drawing-canvas': DrawingCanvasComponent
  }
})
export default class DrawTestComponent extends Vue {
}
